<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col cols="12" md="1"> Filter </v-col>

      <v-col cols="12" md="3">
        <v-select
          dense
          v-model="filter"
          :items="items"
          item-text="filter"
          item-value="filter"
          hide-details="auto"
          flat
          solo
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    filter: "Best Seller",
    items: ["Best Seller", "New Publish", "Most Reviewed"],
  }),
};
</script>
