<template>
  <div class="shop">
    <Banner />
    <!-- <Breadcrumbs /> -->
    <ShopFilter />
    <Products />
  </div>
</template>

<script>
import Banner from "@/components/Shop/Banner.vue";
// import Breadcrumbs from "@/components/Breadcrumbs.vue";
import ShopFilter from "@/components/Shop/Filter.vue";
import Products from "@/components/Shop/Products.vue";
export default {
  name: "Shop",
  components: {
    Banner,
    // Breadcrumbs,
    ShopFilter,
    Products
  },
  data: () => ({}),
};
</script>
